<template>
  <div class="h100">

    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :page.sync="page"
                 :before-open="beforeOpen"
                 @on-load="getList"
                 @row-save="rowSave"
                 @row-update="rowUpdate"
                 :search.sync="search"
                 v-model="form">
    <template slot-scope="{type,size,row,index}" slot="menu">
     <el-button icon="el-icon-plus" :size="size" :type="type" @click="toCreateJob(row,index)">快速创建指令</el-button>
    </template>
        <template slot="commandSearch" slot-scope="scope">
            <el-select v-model="search.command"
                     clearable
                     @change="choseCommand"
                     placeholder="请选择命令动作">
                     <el-option  v-for="(item, i) in commandList" :key="i" 
                     :value="item.identifier"
                         :label="item.desc"
                         ></el-option>
          </el-select>
        </template>
        <template slot="commandForm"
                  slot-scope="scope">
          <el-select v-model="form.command"
                     clearable
                     @change="choseCommand"
                     placeholder="请选择Command">
                     <el-option v-for="(item, i) in commandList" :key="i"
              :value="item.identifier"
                         :label="item.desc"
                         ></el-option>
          </el-select>
        </template>
        <template slot="sendToUserIdsForm"
                  slot-scope="scope">
          <avue-crud 
              :option="sendToUserIdsOption" 
              :data="form.sendToUserIds"
              @row-save="userSave"
              @row-del="userDel"
              > 
              <template slot="nameLabel"
                          slot-scope="scope">
                <div>
                  
                </div>
              </template>
                <template slot="nameForm"
                          slot-scope="scope">
                  <avue-crud
                    :option="userListOption" 
                    :data="userList"
                    :search.sync="userSearch"
                    :page.sync="userPage"
                    @search-change="userSearchChange"
                    @search-reset="searchReset"
                    @selection-change="selectionChange"
                    @on-load="getUserList"
                    >
                  </avue-crud>
                </template>
                
            </avue-crud>
        </template>
      </avue-crud>
    </el-card>
    <el-dialog :visible.sync="dialogJobShow" class="avue-dialog" append-to-body title="快速创建指令" width="50%">
      <el-form :model="jobForm" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="job名称:">
              <el-input v-model="jobForm.name" placeholder="请输入job名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="job描述:">
              <el-input v-model="jobForm.desc" placeholder="请输入job描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="组织:">
              <avue-crud 
                :option="jobOrgDtosOption" 
                :data="jobForm.jobOrgDtos"
                @row-save="orgSave"
                @row-del="orgDel"
                >
                <template slot="nameForm"
                          slot-scope="scope">
                  <el-select v-model="newOrgObj.name"
                            clearable
                            @clear="clearOrg"
                            @change="choseOrg"
                            placeholder="请选择组织">
                            <el-option  v-for="(item,index) in orgList" :key="index"
                                :value="item.id"
                                :label="item.name"
                                ></el-option>
                  </el-select>
                </template>
                <template slot="codeForm"
                          slot-scope="scope">
                  <el-input v-model="newOrgObj.code" disabled></el-input>
                </template>
            </avue-crud>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="任务列表:">
                  <avue-crud 
                        :option="taskDtosOption" 
                        :data="jobForm.taskDtos"
                        >
                    </avue-crud>
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogJobShow = false">取 消</el-button>
        <el-button type="primary" @click="confirmToCreateJob">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrglist,update as createJob } from '@/api/condition2.0/pageJobs.js'
import { list,update,templateUserList,getTemplateUser } from '@/api/condition2.0/pageTasks.js'
import { list as getCommandListApi } from '@/api/condition2.0/listCommands.js'
import { url } from '@/config'
let that;
export default window.$crudCommon({
  data(){
    return {
      taskList:[],
      newTaskObj:{
        name:'',
        desc:'',
        command:'',
        commandDesc:'',
        id:'',
        content:''
      },
      taskDtosOption:{
        align: 'center',
        headerAlign: 'center',
        menu:false,
        menuWidth:220,
        copyBtn:false,
        addBtn:false,
        editBtn:false,
        delBtn:false,
        column: [{
            label: '任务名称',
            type: 'select',
            prop: 'name',
            width:120,
            span:24,
            formslot: true
        }, {
            label: '任务描述',
            prop: 'desc',
            span:24,
            formslot: true
        }, {
            label: '命令动作',
            prop: 'commandDesc',
            span:24,
            formslot: true
        }, {
            label: 'content',
            prop: 'content',
            span:24,
            overHidden:true,
            formslot: true
        }]
    },
      newOrgObj:{
        id:'',
        name:'',
        code:''
      },
      orgList:[],
      jobOrgDtosOption:{
        align: 'center',
        headerAlign: 'center',
        menu:true,
        menuWidth:150,
        copyBtn:false,
        editBtn:false,
        column: [{
            label: '组织名称',
            type: 'select',
            prop: 'name',
            width:120,
            span:24,
            formslot: true
        }, {
            label: '组织code',
            prop: 'code',
            span:24,
            formslot: true
        }]},
      dialogJobShow:false,
      jobForm:{
        name:'',
        desc:'',
        jobOrgDtos:[],
        taskDtos:[]
      },
      commandList:[],
      saveDuplicateFlag:false,
      updateDuplicateFlag:false,
      sendToUserIdsOption:{
        align: 'center',
        headerAlign: 'center',
        menu:true,
        menuWidth:150,
        copyBtn:false,
        editBtn:false,
        column: [{
            label: '用户名',
            labelslot:true,
            prop: 'name',
            width:120,
            span:24,
            formslot: true
        },{
            label: '组织架构',
            prop: 'orgId',
            display:false
        },{
            label: '职位',
            prop: 'position',
            display:false
        },{
            label: '手机号',
            prop: 'phone',
            display:false
        }],
      },
      userSearch:{
        "orgId": "",
        "phone": "",
        "name": ""
      },
      userListOption:{
        align: 'center',
        headerAlign: 'center',
        menu:false,
        addBtn:false,
        copyBtn:false,
        editBtn:false,
        selection:true,
        page:true,
        column: [{
            label: '用户名',
            prop: 'name',
            search:true,
            span:24
        },{
            label: '组织架构',
            prop: 'orgId',
            search:true,
            display:false,
            dicUrl: url + '/org-manage/list?size=100',
            dicFormatter: (data) => {
              return data.records
            },
            props: {
              label: 'name',
              value: 'id'
            },
        },{
            label: '职位',
            prop: 'position',
            display:false
        },{
            label: '手机号',
            prop: 'phone',
            search:true,
            display:false
        }]
      },
      userList:[],
      selectedUsers:[],
      userPage:{

      },
      
    }
  },
  computed: {
    
  },
  filters: {
    takeDesc(value){
      console.log(that)
      // console.log(this.commandList)
      // if(this.commandList.length){
        
      // console.log(value)
      // let returnStr = this.commandList.find(item=> {return item.identifier == value})
      // return returnStr.desc
      //}
    }
  },
  created(){
    this.getCommandList()
    this.getUserList()
    this.getOrgList()
    this.form.sendToUserIds = [];
  },
  methods: {
    confirmToCreateJob(){
      if(!this.jobForm.jobOrgDtos.length){
        this.$message.warning('请至少选择一个组织')
        return;
      }
      this.$confirm("确定创建指令?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          // 处理taskDtos和jobOrgDtos
          let taskDtos  = this.jobForm.taskDtos.map(item => {return item.id})
          console.log(taskDtos)
          let jobOrgDtos  = this.jobForm.jobOrgDtos.map(item => {return item.id})
          console.log(jobOrgDtos)
          let data = {
            jobId:'',
            jobDesc:this.jobForm.desc,
            jobName:this.jobForm.name,
            orgIds:jobOrgDtos,
            taskIds:taskDtos
          }
          createJob(data).then(res=>{
            this.$message.success('创建成功')
            this.getList();
            this.dialogJobShow = false;
            this.jobForm = {
              name:'',
              desc:'',
              jobOrgDtos:[],
              taskDtos:[]
            }
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消创建快捷指令",
          });
        });  
      
    },
    choseOrg(val){
      let findOrgObj = this.orgList.find(ele => ele.id == val);
      this.newOrgObj={
        name:findOrgObj.name,
        id:findOrgObj.id,
        code:findOrgObj.code
      }
    },
    clearOrg(){
      this.newOrgObj={
        name:"",
        id:"",
        code:""
      }
    },
    getOrgList(){
      getOrglist().then(res=>{

        let data = res;
        this.orgList = data;
      })
    },
    orgSave(row, done, loading){
      if(this.newOrgObj.name == ''){
        this.$message.warning('请选择组织')
        loading();
        return;
      }
      // 判断组织是否已存在
      let exitOrgObj = this.jobForm.jobOrgDtos.find(ele => ele.id == this.newOrgObj.id);
      console.log(exitOrgObj)
      if(exitOrgObj){
        this.$message.warning('该组织已存在,请勿重复添加')
        loading();
        return;
      }
      console.log(this.jobForm.jobOrgDtos)
      this.jobForm.jobOrgDtos.push(this.newOrgObj);
      this.newOrgObj={
        id:'',
        name:'',
        code:''
      }
      done()
    },
    orgDel(row, index){
      this.jobForm.jobOrgDtos.splice(index,1);
    },
    toCreateJob(row,index){
       console.log(row,index)
      this.jobForm.name = row.name;
      this.jobForm.desc = row.desc;
      //this.taskList.push(row);
      let obj = {
        id:row.id,
        name:row.name,
        commandDesc:row.commandDesc,
        command:row.command,
        content:row.content,
        desc:row.desc
      }
      this.jobForm.taskDtos = [];
      this.jobForm.taskDtos.push(obj);
      this.dialogJobShow = true
    },
     beforeOpen (done, type) {
      if (type == 'edit') {
        this.form.sendToUserIds = []
      /** 
       * content类型
       * 字符串：
       * SCREEN_INTERACT_TTS:语音播报
       * SCREEN_INTERACT_CHANGE_SCREEN：切换大屏
       * JSON：
       * ALI_TEL_NOTICE：电话通知
       * SEND_WECHAT_TEMPLATE_MESSAGE：模板消息
       **/
        let contentObj = (this.form.command.indexOf('TEMPLATE_MESSAGE') != -1)||this.form.command == 'ALI_TEL_NOTICE' ? JSON.parse(this.form.content) : this.form.content;
        console.log(contentObj)
        if(contentObj&&contentObj.sendToUserIds || this.form.command.indexOf('TEMPLATE_MESSAGE') != -1){
          this.findObject(this.option.column,'sendToUserIds').display = true;
          if(contentObj.sendToUserIds && contentObj.sendToUserIds.length) this.getTemplateUser(contentObj.sendToUserIds)
        }
      }
      done()
    },
    getTemplateUser(ids){
      
      getTemplateUser(ids).then(res=>{
        this.form.sendToUserIds = res
      })
    },
    // userSearch(){
    //   console.log('userSearchuserSearchuserSearchuserSearch')
    // },
    selectionChange(list){
      // 选中的数据
      this.selectedUsers = list
    },
    searchReset(){
      this.userSearch = {
        "orgId": "",
        "phone": "",
        "name": ""
      }
    },
    userSearchChange(form,done){
      console.log(form)
      console.log("userSearchChange")
      this.getUserList(form)
      done()
    },
    getUserList(searchObj){
      templateUserList(searchObj).then(res =>{
        this.userList = res.content;
        this.userPage.total = res.totalElements;
      })
    },
    userSave(row, done, loading){
      this.form.sendToUserIds = this.form.sendToUserIds.concat(this.selectedUsers)
      done()
    },
    userDel(row,index){
        this.form.sendToUserIds.splice(index,1)
    },
    choseCommand(val){
      if(val.indexOf('TEMPLATE_MESSAGE') != -1){
        
        this.findObject(this.option.column,'sendToUserIds').display = true;
        this.form.sendToUserIds = [];
      }else{

        this.findObject(this.option.column,'sendToUserIds').display = false;
      }
    },
    getCommandList(){
      //this.commandList
      getCommandListApi().then(res=>{
        this.commandList = res
        console.log(this.commandList)
        this.getList();
      })
    },
    rowSave(row, done, loading){

      if(this.saveDuplicateFlag) return; 

      let sendToUserIdsArr = []; 
      let contentObj = {};
      
      /** 
       * content类型
       * 字符串：
       * SCREEN_INTERACT_TTS:语音播报
       * SCREEN_INTERACT_CHANGE_SCREEN：切换大屏
       * JSON：
       * ALI_TEL_NOTICE：电话通知
       * SEND_WECHAT_TEMPLATE_MESSAGE：模板消息
       **/
      let content = (this.form.command.indexOf('TEMPLATE_MESSAGE') != -1)||this.form.command == 'ALI_TEL_NOTICE' ? JSON.parse(row.content) : row.content;
       
      if(this.form.command.indexOf('TEMPLATE_MESSAGE') != -1){

        sendToUserIdsArr = this.form.sendToUserIds.map(item=>{
          return item.id
        })
        contentObj = JSON.stringify(Object.assign({},content,{sendToUserIds:sendToUserIdsArr}))
      }else if (this.form.command == 'ALI_TEL_NOTICE'){

        contentObj = JSON.stringify(content)
      }else{
        contentObj = content
      }
      
      let dataObj = {
        id:'',
        name:row.name,
        desc:row.desc,
        command:row.command,
        content:contentObj
      }
      this.saveDuplicateFlag = true;
      update(dataObj).then(() => {
        this.$message.success('新增成功')
        this.getList();
        done()
        this.saveDuplicateFlag = false;
        this.findObject(this.option.column,'sendToUserIds').display = false;
      }).catch(() => {
        loading()
        this.saveDuplicateFlag = false;
      })
    },
    rowUpdate(row, index, done){
      if(this.updateDuplicateFlag) return;

      let sendToUserIdsArr = []; 
      let contentObj = {};
      /** 
       * content类型
       * 字符串：
       * SCREEN_INTERACT_TTS:语音播报
       * SCREEN_INTERACT_CHANGE_SCREEN：切换大屏
       * JSON：
       * ALI_TEL_NOTICE：电话通知
       * SEND_WECHAT_TEMPLATE_MESSAGE：模板消息
       **/
      let content = (this.form.command.indexOf('TEMPLATE_MESSAGE') != -1)||this.form.command == 'ALI_TEL_NOTICE' ? JSON.parse(row.content) : row.content;
      if(this.form.command.indexOf('TEMPLATE_MESSAGE') != -1){

        sendToUserIdsArr = this.form.sendToUserIds.map(item=>{
          return item.id
        })
        contentObj = JSON.stringify(Object.assign({},content,{sendToUserIds:sendToUserIdsArr}))
      }else if (this.form.command == 'ALI_TEL_NOTICE'){

        contentObj = JSON.stringify(content)
      }else{
        contentObj = content
      }
      if(!this.form.sendToUserIds.length) delete contentObj.sendToUserIds;

      let dataObj = {
        id:row.id,
        name:row.name,
        desc:row.desc,
        command:row.command,
        content:contentObj
      }
      this.updateDuplicateFlag = true;
      update(dataObj).then(() => {
        this.$message.success('更新成功')
        this.getList();
        this.findObject(this.option.column,'sendToUserIds').display = false;
        done()
        this.updateDuplicateFlag = false;
      }).catch(() => {
        loading()
        this.updateDuplicateFlag = false;
      })
    },
    getList(){
      console.log(this.page.currentPage-1)
      list({page:this.page.currentPage-1,size:this.page.pageSize},{taskName:this.search.name,command:this.search.command}).then(res=>{
        console.log(res)
        let data = res.content;
        this.data = data;
        this.page.total = res.totalElements;
      })
    }
  }
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/condition2.0/pageTasks',
  res: (data) => {
    return {
      total: data.content.length,
      data: data.content
    }
  }
})
</script>

<style lang="scss" scoped>
</style>